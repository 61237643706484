import { render, staticRenderFns } from "./Add.vue?vue&type=template&id=4dbedb66&scoped=true&"
import script from "./Add.vue?vue&type=script&lang=js&"
export * from "./Add.vue?vue&type=script&lang=js&"
import style0 from "./Add.vue?vue&type=style&index=0&id=4dbedb66&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dbedb66",
  null
  
)

export default component.exports